import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import SideBar from "./sideBar"
import st from "./css/header.module.scss"

const Header = ({ cur, sticky, isMobile, isWx }) => {
  const navs = isMobile
    ? [
        { path: "/advance/", name: "优势" },
        { path: "/cases/", name: "案例" },
        { path: "/list/", name: "帮助" },
      ]
    : [
        { path: "/", name: "首页" },
        { path: "/advance/", name: "优势" },
        { path: "/price/", name: "价格" },
        { path: "/features/", name: "功能介绍" },
        { path: "/cases/", name: "客户案例" },
        { path: "/list/", name: "帮助中心" },
        { path: "/fqa/", name: "常见问题" },
        { path: "/aboutus.html", name: "关于我们" },
      ]

  return (
    <header
      className={classNames(st.header, { [st.sticky]: isMobile || sticky })}
    >
      <Link className={st.logo} to="/">
        <b>约课、拓客、智慧经营</b>
      </Link>
      <nav className={st.nav}>
        {navs.map(v => (
          <Link
            key={v.path}
            className={classNames(st.navItem, { [st.cur]: cur === v.path })}
            to={v.path}
          >
            {v.name}
          </Link>
        ))}
      </nav>
      <SideBar cur={cur} isMobile={isMobile} isWx={isWx} />
    </header>
  )
}

Header.propTypes = {
  cur: PropTypes.string,
}

Header.defaultProps = {
  cur: ``,
}

export default Header
